.b-title {
    font-weight: 500;
    &--main {
        margin-bottom: $base-spacing-m;
    }
}
.b-icon {
    height: 14px !important;
    font-size: 18px;
}

.b-modal-info-section {
    background-color: $color-back-secondary;
    border-radius: 16px;    
} 

.b-btn {
    border-radius: 8px;
    font-weight: 600;
    width: 131px;
    height: 70px;
    padding: 0px 32px;
    background-color: $color-corporative--main;
    color: $color-font-base;
    margin: auto;
    &:active {
        background-color: $color-corporative--active;
    }
    &--big {
        width: 311px;
        height: 70px;
        padding: 14px 32px;
    }
    &--back {
        background-color: $color--white;
        border: 2px solid $color-corporative--main;
    }
    &--disabled {
        background-color: $color--disabled;
        color: $color--white;
    }
    &--cancel {
      background-color: $color--error;
    }
}

.b-table-vertical {
    display: grid;
    grid-template-columns: 2fr 3fr;
    // &__item {

    // }
}

.b-header {
    display: flex;
    justify-content: space-between;

    &__menu {
        margin-bottom: 21px;
        margin-top: 14px;
        padding: 0 21px;
        font-size: 21px;
    }

}