//colours
$color--white: #FFFFFF;
$color-font-base: #3B4645;
$color-back-secondary: #F7F7F7;
$color-corporative--main: #FFA829;
$color-corporative--active: #F59100;
$color--error: #B81F1D;
$color--success: #23A942;
$color--disabled: #C7CBCA;



