/* You can add global styles to this file, and also import other style files */

@import "./styles/variables.settings";
@import "./styles/reset.settings";
@import "./styles/base.settings";
@import "./styles/elements";
@import "./styles/objects";
@import "./styles/blocks";
@import "./styles/utilities";

#component{
    margin-top: 3%;
    margin-left: 3%;
    width: 94%;
    height: 98%;
    background-color: white;
}

.title-card{
    text-align: center;
    width: 100%;
    margin-top: 10px !important;
}

.title-container{
    text-align: center;
    width: 100%;
    margin-top: 45px !important;
}

.text-container{
    text-align: center;
    width: 100%;
    margin-top: 15px !important;
}

.title{
    color: white;
    font-weight: 550 !important;
    width: 100%;
    text-align: center;
}

.toolbar{
    height: 8%;
}

.loadingDiv{
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
}

mat-progress-bar {
    background: rgba(255, 212, 57, 0.288);
  }

.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #ffc003;
}