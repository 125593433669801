ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
h1,h2,h3 {
    margin: 0 !important;   
}
button {
    border: none;
}
.mat-mdc-form-field-focus-overlay, 
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $color--white;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $color-corporative--main;

}
.mdc-text-field--filled .mdc-line-ripple:after {
    border-bottom-color: var(--mdc-theme-primary, $color-corporative--main) !important;
}
.mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #FFA829 !important;
    --mdc-slider-focus-handle-color: #FFA829 !important;
    --mdc-slider-hover-handle-color: #FFA829 !important;
    --mdc-slider-active-track-color: #FFA829 !important;
    --mdc-slider-inactive-track-color: #FFA829 !important;
    // --mdc-slider-with-tick-marks-active-container-color: #fff !important;
    --mdc-slider-with-tick-marks-inactive-container-color: #FFA829 !important;
    --mat-mdc-slider-ripple-color: #FFA829 !important;
    // --mat-mdc-slider-hover-ripple-color: rgba(103, 58, 183, .05) !important;
    // --mat-mdc-slider-focus-ripple-color: rgba(103, 58, 183, .2) !important;
}