/* You can add global styles to this file, and also import other style files */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  margin: 0 !important;
}

button {
  border: none;
}

.mat-mdc-form-field-focus-overlay,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #FFFFFF;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #FFA829;
}

.mdc-text-field--filled .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-primary, #FFA829) !important;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #FFA829 !important;
  --mdc-slider-focus-handle-color: #FFA829 !important;
  --mdc-slider-hover-handle-color: #FFA829 !important;
  --mdc-slider-active-track-color: #FFA829 !important;
  --mdc-slider-inactive-track-color: #FFA829 !important;
  --mdc-slider-with-tick-marks-inactive-container-color: #FFA829 !important;
  --mat-mdc-slider-ripple-color: #FFA829 !important;
}

/* Global base
   ****************************************************************************/
/* Spacing
   ****************************************************************************/
/* Font size
   ****************************************************************************/
html, body {
  height: 99%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/**
 * The container object simply containeres off content. Extend with cosmetic styles in the
 * Components layer with size variations
 *
 * .o-content: padding top and bottom 
 * .o-wrap: padding sides
 * .o-box: padding all 
 */
.o-content {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  /* Size variants
     ****************************************************************************/
}
.o-content--zero {
  padding: 0;
}
.o-content--m {
  padding-top: 14px;
  padding-bottom: 14px;
}
.o-content--l {
  padding-top: 21px;
  padding-bottom: 21px;
}
.o-content--no-top {
  padding-top: 0;
}

.o-wrap {
  display: block;
  padding-left: 7px;
  padding-right: 7px;
  /* Size variants
     ****************************************************************************/
}
.o-wrap--zero {
  padding: 0;
}
.o-wrap--m {
  padding-left: 14px;
  padding-right: 14px;
}

.o-box {
  display: block;
  padding: 7px;
  /* Size variants
     ****************************************************************************/
}
.o-box--zero {
  padding: 0;
}
.o-box--m {
  padding: 14px;
}
.o-box--l {
  padding: 21px;
}

.b-title {
  font-weight: 500;
}
.b-title--main {
  margin-bottom: 14px;
}

.b-icon {
  height: 14px !important;
  font-size: 18px;
}

.b-modal-info-section {
  background-color: #F7F7F7;
  border-radius: 16px;
}

.b-btn {
  border-radius: 8px;
  font-weight: 600;
  width: 131px;
  height: 70px;
  padding: 0px 32px;
  background-color: #FFA829;
  color: #3B4645;
  margin: auto;
}
.b-btn:active {
  background-color: #F59100;
}
.b-btn--big {
  width: 311px;
  height: 70px;
  padding: 14px 32px;
}
.b-btn--back {
  background-color: #FFFFFF;
  border: 2px solid #FFA829;
}
.b-btn--disabled {
  background-color: #C7CBCA;
  color: #FFFFFF;
}
.b-btn--cancel {
  background-color: #B81F1D;
}

.b-table-vertical {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.b-header {
  display: flex;
  justify-content: space-between;
}
.b-header__menu {
  margin-bottom: 21px;
  margin-top: 14px;
  padding: 0 21px;
  font-size: 21px;
}

.align-right {
  text-align: right;
}

#component {
  margin-top: 3%;
  margin-left: 3%;
  width: 94%;
  height: 98%;
  background-color: white;
}

.title-card {
  text-align: center;
  width: 100%;
  margin-top: 10px !important;
}

.title-container {
  text-align: center;
  width: 100%;
  margin-top: 45px !important;
}

.text-container {
  text-align: center;
  width: 100%;
  margin-top: 15px !important;
}

.title {
  color: white;
  font-weight: 550 !important;
  width: 100%;
  text-align: center;
}

.toolbar {
  height: 8%;
}

.loadingDiv {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
}

mat-progress-bar {
  background: rgba(255, 212, 57, 0.288);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ffc003;
}