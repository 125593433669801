/* Global base
   ****************************************************************************/

   $base-line: 7px;

   /* Spacing
      ****************************************************************************/
   

   $base-spacing-factor-s: 0.5 !default;
   $base-spacing-factor-m: 2 !default;
   $base-spacing-factor-l: 3 !default;
   
   $base-spacing-s: $base-line * $base-spacing-factor-s !default; // 7 * 0.5 = 3.5
   $base-spacing: $base-line !default; // 7
   $base-spacing-m: $base-line * $base-spacing-factor-m !default;// 7 * 2 = 14
   $base-spacing-l: $base-line * $base-spacing-factor-l !default; // 7 * 3 = 21

   
   /* Font size
      ****************************************************************************/
   
   $base-factor-font-size: 2;
   $base-font-size: $base-line * $base-factor-font-size; // 7 * 2 = 14