/**
 * The container object simply containeres off content. Extend with cosmetic styles in the
 * Components layer with size variations
 *
 * .o-content: padding top and bottom 
 * .o-wrap: padding sides
 * .o-box: padding all 
 */

 .o-content {
    display: block;
    padding-top: $base-spacing;
    padding-bottom: $base-spacing;
  
    /* Size variants
       ****************************************************************************/
  
    &--zero {
      padding: 0;
    }
    &--m {
      padding-top: $base-spacing-m;
      padding-bottom: $base-spacing-m;
    }
    &--l {
      padding-top: $base-spacing-l;
      padding-bottom: $base-spacing-l;
    }
    &--no-top {
      padding-top: 0;
    }
  }

.o-wrap {
   display: block;
   padding-left:  $base-spacing;
   padding-right:  $base-spacing;
 
   /* Size variants
      ****************************************************************************/
 
   &--zero {
     padding: 0;
   }
 
   &--m {
     padding-left: $base-spacing-m;
     padding-right: $base-spacing-m;
   }

}

.o-box {
   display: block;
   padding: $base-spacing;
 
   /* Size variants
      ****************************************************************************/
 
   &--zero {
     padding: 0;
   }
 
   &--m {
     padding: $base-spacing-m;
   }
   &--l {
      padding: $base-spacing-l;
    }
}